import React, { ReactElement } from 'react';

import { Link, useTranslation } from 'gatsby-plugin-react-i18next';
import { StaticImage } from 'gatsby-plugin-image';
import { graphql } from 'gatsby';
import BgImage from '@/components/BgImage';
import NavBar from '@/components/NavBar';
import Footer from '@/components/Footer';

import { bgTearTop } from './about.module.css';

import { heroImg } from '@/styles/hero.module.css';
import Head from '@/components/Head';
import HeroAbout from '@/components/heroes/HeroAbout';

export default function AboutPage(): ReactElement {
  const { t } = useTranslation();
  return (
    <>
      <Head title={`${t('about.about')} | ${t('kota_olive_oil')}`} />

      <NavBar />
      {/* Hero with it's own bg image */}
      <main className="h-9/16 md:h-screen" style={{ paddingTop: '60px' }}>
        <div className="w-full h-full d-flex relative -z-1">
          {/* Fix for borked lighthouse tooling expecting content and failing to give score */}
          <div className="absolute left-0 top-0" aria-hidden>
            Kota
          </div>
          <HeroAbout />
        </div>
      </main>

      {/* Everything that will have the bg image goes here, BgImage is contained here */}
      <div className="relative">
        <BgImage />
        <section className="flex relative py-32">
          <div
            className="hidden lg:block absolute -z-1 left-0 top-0 bottom-0"
            style={{ width: '53%' }}
          >
            <StaticImage
              src="../assets/kota-spoon.png"
              className={`-z-1 ${heroImg}`}
              alt="Background image"
              layout="constrained"
              objectFit="cover"
              imgStyle={{ objectPosition: 'right 65%' }}
              sizes="53wv"
            />
          </div>
          <div className="flex flex-wrap items-center max-w-screen-xl px-2 mx-auto w-full justify-center text-center md:text-left">
            <div className="w-full">
              <div className="lg:w-1/2  px-8 lg:px-16 ml-auto">
                <h2 className="above-title-text text-gray-light mb-6">
                  {t('about.kota_olive_oil_abovetitle')}
                </h2>
                <h1 className="title-text mb-10">
                  {t('about.kota_olive_oil_title')}
                </h1>

                <p className="body-text text-gray-light mb-8">
                  {t('about.kota_olive_oil_text')}
                </p>

                <Link to="/products" className="btn">
                  {t('about.more_about_products')}
                </Link>
              </div>
            </div>

            <div className="flex flex-wrap w-full mt-32 lg:mt-64">
              <div className="w-full px-8 lg:px-16">
                <h2 className="above-title-text text-gray-light mb-6">
                  {t('about.eco_production_abovetitle')}
                </h2>
                <h1 className="title-text mb-10">
                  {t('about.eco_production_title')}
                </h1>
              </div>
              <div className="lg:w-1/2 px-8 lg:px-16">
                <p className="body-text text-gray-light mb-8">
                  {t('about.eco_production_text1')}
                </p>
              </div>

              <div className="md:w-1/2 px-8 lg:px-16">
                <p className="body-text text-gray-light mb-8">
                  {t('about.eco_production_text2')}
                </p>
              </div>
            </div>
          </div>
        </section>

        <section
          className={`flex w-full flex-wrap-reverse -mt-12 ${bgTearTop}`}
        >
          <div className="w-full lg:w-1/2 xl:w-7/12 flex">
            <div className="flex flex-col justify-center mx-auto max-w-screen-md px-16 lg:px-28 py-16 lg:py-32 lg:pt-48">
              <h2 className="above-title-text text-gray-dark mb-6">
                {t('about.quality_abovetitle')}
              </h2>
              <h1 className="title-text mb-10">{t('about.quality_title')}</h1>
              <p className="body-text text-gray-darker mb-28">
                {t('about.quality_text')}
              </p>

              <h2 className="above-title-text text-gray-dark mb-6">
                {t('about.science_tradition_abovetitle')}
              </h2>
              <h1 className="title-text mb-10">
                {t('about.science_tradition_title')}
              </h1>
              <p className="body-text text-gray-darker">
                {t('about.science_tradition_text')}
              </p>
            </div>
          </div>
          <div className="w-full lg:w-1/2 xl:w-5/12 relative">
            <StaticImage
              alt="Boca ulja"
              src="../assets/about-side.png"
              sizes="(min-width: 1280px) 42vw, (min-width: 1024px) 50vw, 100vw"
            />
          </div>
        </section>
      </div>

      <Footer />
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
